import { SearchClient } from "algoliasearch/lite";
import _, { find, keys, map } from "lodash";
import React from "react";
import { Configure } from "react-instantsearch-dom";
import { Col, Row, Table } from "reactstrap";
import { ProductCategoryId } from "../../../resbutler-utils/types/ProductCategoryId";
import { ProductSearchResult, ProductSize, ProductTypeId } from "../../../resbutler-utils/types/product";
import { getImagesFromProduct } from "../../../resbutler-utils/utils/menuUtils";
import { DynamicAlgoliaSearch } from "../DynamicAlgoliaSearch";

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const Hits = ({ selected, setSelected, hits, sizes, restaurantId, limitToOne, limitToOneSize, clientId, publicStorageBucket }: { selected: ProductSearchResult[]; setSelected: any; hits: any; sizes: ProductSize[]; restaurantId: string; limitToOne: boolean; limitToOneSize: boolean; clientId: string; publicStorageBucket: string }) => {
  return (
    <div className="mt-2">
      <Table>
        <tbody>
          {map(hits, (hit) => {
            const index = _.findIndex(selected, (s) => s.productId === hit.objectID);

            const { thumbnail } = getImagesFromProduct(hit, publicStorageBucket, clientId);
            return (
              <tr key={hit.objectID} style={{ border: index > -1 ? "2px solid blue" : "none" }}>
                <td>
                  {hit.name}
                  {(hit.typeId === ProductTypeId.food || hit.typeId === ProductTypeId.beverage || hit.typeId === ProductTypeId.combinationFoodAndBeverage || hit.productCategoryId === ProductCategoryId.Inventory) && hit.sizes && (
                    <ul style={{ listStyleType: "none" }}>
                      {keys(hit.sizes).map((sizeId) => {
                        if (hit.sizes[sizeId]) {
                          const productSize = find(sizes, (p) => p.id === sizeId);
                          const priceEnabled = hit?.restaurants?.[restaurantId]?.price?.[sizeId] !== undefined && hit.restaurants[restaurantId].enabled === true;
                          return (
                            <li key={`${hit.objectID}-${sizeId}`}>
                              <div className="checkbox c-checkbox">
                                <label className="mb-2" htmlFor={`${hit.objectID}-${sizeId}`}>
                                  <input
                                    id={`${hit.objectID}-${sizeId}`}
                                    type="checkbox"
                                    disabled={(!priceEnabled && restaurantId !== "") || _.findIndex(selected, (s: any) => s.uneditableSizes?.[sizeId] && s.productId === hit.objectID) > -1}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (limitToOne) {
                                          setSelected([
                                            {
                                              productId: hit.objectID,
                                              sizes: { [sizeId]: true },
                                            },
                                          ]);
                                        } else {
                                          if (index === -1)
                                            setSelected(
                                              selected.concat({
                                                productId: hit.objectID,
                                                sizes: {
                                                  ...(limitToOneSize ? {} : selected[index]?.sizes),
                                                  [sizeId]: true,
                                                },
                                              })
                                            );
                                          else
                                            setSelected([
                                              ...selected.slice(0, index),
                                              {
                                                ...selected[index],
                                                sizes: {
                                                  ...(limitToOneSize ? {} : selected[index]?.sizes),
                                                  [sizeId]: true,
                                                },
                                              },
                                              ...selected.slice(index + 1),
                                            ]);
                                        }
                                      } else {
                                        setSelected([
                                          ...selected.slice(0, index),
                                          {
                                            ...selected[index],
                                            sizes: {
                                              ...selected[index]?.sizes,
                                              [sizeId]: false,
                                            },
                                          },
                                          ...selected.slice(index + 1),
                                        ]);
                                      }
                                    }}
                                    checked={_.findIndex(selected, (s) => s.sizes?.[sizeId] && s.productId === hit.objectID) > -1 ? true : false}
                                  />
                                  <span className="fa fa-check" />
                                  {productSize?.name} {priceEnabled ? `(${formatter.format(hit.restaurants[restaurantId].price[sizeId])})` : ""}
                                </label>
                              </div>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  )}
                  {hit.typeId === ProductTypeId.fixedPriceMenu && (
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <div className="checkbox c-checkbox">
                          <label className="mb-2 pe-2" htmlFor={hit.objectID}>
                            <input
                              id={hit.objectID}
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (!limitToOne) setSelected([...selected.slice(0, index), { productId: hit.objectID }, ...selected.slice(index + 1)]);
                                  else setSelected([{ productId: hit.objectID }]);
                                } else {
                                  setSelected(_.filter(selected, (s) => s.productId !== hit.objectID));
                                }
                              }}
                              checked={_.findIndex(selected, (s) => s.productId === hit.objectID) > -1 ? true : false}
                            />
                            <span className="fa fa-check" />
                            {hit.fixedPriceMenuTypes.adult?.charAt(0).toUpperCase() + hit.fixedPriceMenuTypes.adult?.slice(1)}
                          </label>
                        </div>
                      </li>
                    </ul>
                  )}
                  {(Number(hit.typeId) === ProductTypeId.time || Number(hit.typeId) === ProductTypeId.financial || Number(hit.typeId) === ProductTypeId.depositMinSpend || Number(hit.typeId) === ProductTypeId.customerServiceExpense) && (
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <div className="checkbox c-checkbox">
                          <label className="mb-2 pe-2" htmlFor={hit.objectID}>
                            <input
                              id={hit.objectID}
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (!limitToOne) {
                                    if (index >= 0) {
                                      setSelected([...selected.slice(0, index), { productId: hit.objectID }, ...selected.slice(index + 1)]);
                                    } else {
                                      setSelected([...selected, { productId: hit.objectID }]);
                                    }
                                  } else {
                                    setSelected([{ productId: hit.objectID }]);
                                  }
                                } else {
                                  setSelected(_.filter(selected, (s) => s.productId !== hit.objectID));
                                }
                              }}
                              checked={_.findIndex(selected, (s) => s.productId === hit.objectID) > -1 ? true : false}
                            />
                            <span className="fa fa-check" />
                            {hit.name}
                          </label>
                        </div>
                      </li>
                    </ul>
                  )}
                </td>
                <td>{hit.image && <img src={thumbnail} style={{ maxHeight: 100, maxWidth: 100 }} />}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export interface ProductSearchProps {
  sizes?: ProductSize[];
  onChange?: any;
  query?: string;
  limitToOne?: boolean;
  restaurantId?: string;
  data?: ProductSearchResult[];
  searchClient: SearchClient;
  tenantId?: string;
  clientId: string;
  publicStorageBucket: string;
  limitToOneSize?: boolean;
}

export const ProductSearch = ({ sizes, onChange, query, limitToOne, restaurantId, data, searchClient, tenantId, clientId, publicStorageBucket, limitToOneSize }: ProductSearchProps) => {
  return (
    <DynamicAlgoliaSearch
      searchClient={searchClient}
      indexName={`${tenantId.replace("-", "_")}_products`}
      renderSearchInput={({ currentRefinement, isSearchStalled, refine }) => {
        return (
          <React.Fragment>
            <input type="search" value={currentRefinement} className="form-control" onChange={(event) => refine(event.currentTarget.value)} placeholder="Search Products" />
            {isSearchStalled ? "My search is stalled" : ""}
          </React.Fragment>
        );
      }}
      renderHits={(params) => {
        return <Hits sizes={sizes} restaurantId={restaurantId} selected={data} setSelected={onChange} limitToOne={limitToOne} limitToOneSize={limitToOneSize} clientId={clientId} publicStorageBucket={publicStorageBucket} {...params} />;
      }}
    >
      {({ renderSearchBox, renderSearchMenu, renderSearchHits, renderSearchPagination }) => {
        return (
          <React.Fragment>
            <Configure filters={query} />
            <Row>
              <Col lg={12}>{renderSearchBox()}</Col>
              <Col lg={4}>
                <div className="mt-2">{renderSearchMenu()}</div>
              </Col>
              <Col lg={8}>{renderSearchHits()}</Col>
            </Row>
            <Row>
              <Col lg={12}>{renderSearchPagination()}</Col>
            </Row>
          </React.Fragment>
        );
      }}
    </DynamicAlgoliaSearch>
  );
};
