import { chain, compact, find, findKey, flatMap, forEach, has, keys, map, orderBy, values } from "lodash";
import { Moment } from "moment";
import { Booking } from "../types/Booking";
import { FunctionBooking } from "../types/FunctionBooking";
import { GroupHeadingProductSizes, GroupHeadings } from "../types/Menu";
import { OrderItem, OrderItemProducts, OrderItemStatus, OrderModifier, OrderModifiers } from "../types/Order";
import { Adjustment, Adjustments, OpeningTimes } from "../types/Seating";
import { ComboGroup, GroupHeadingHierachy, PriceOption, PriceOptions, Product, ProductSize, SpecialProduct, StockLimitProducts } from "../types/product";
import { canThisBeNumber, getOpeningTimesAdjusted, getTicks } from "./../utils";
import { isStatusEqualByBooking } from "./bookingUtils";
import { toCurrency } from "./formatUtils";

export function validateProductPageModal(values: OrderItem, product: Product) {
  const errors: any = { comboGroup: [], modifiers: {}, additions: {}, preparations: {} };
  for (let i = 0; i < product?.groupProducts?.[values.sizeId]?.length; i++) {
    let comboCount = 0;
    const comboGroup = product.groupProducts[values.sizeId][i];
    if (comboGroup.includeAllCombinations) continue;
    for (const productId1 in comboGroup.combinations) {
      if (values.comboGroup?.[productId1]?.quantity) comboCount += Number(values.comboGroup?.[productId1]?.quantity);
    }
    if (comboCount < Number(comboGroup.quantity)) {
      errors.comboGroup[i] = "Required";
    } else if (comboCount > Number(comboGroup.quantity)) {
      errors.comboGroup[i] = "Cannot Exceed Limit";
    }
  }
  for (let i = 0; i < product?.modifiers?.length; i++) {
    const mod = product.modifiers[i];
    if (mod.mandatory && !values.modifiers?.[mod.modifierGroupId]) {
      errors.modifiers[mod.modifierGroupId] = "Required";
    }
  }
  for (let i = 0; i < product?.additions?.length; i++) {
    const addition = product.additions[i];
    let additionCount = 0;
    for (const additionProductId in values.additions?.[addition.additionGroupId]) {
      if (values.additions[addition.additionGroupId][additionProductId].quantity > 0) {
        additionCount += 1;
      }
    }
    if (addition.mandatory && additionCount < 1) errors.additions[addition.additionGroupId] = "Required";
    else if (addition.limitedSelection && additionCount > Number(addition.totalselections)) errors.additions[addition.additionGroupId] = "Cannot Exceed Limit";
  }
  for (let i = 0; i < product?.preparations?.length; i++) {
    const preparation = product.preparations[i];
    const selectedSizeHasPreparation = (preparation.sizes && values.sizeId && preparation.sizes[values.sizeId]) || false;
    const preparationCount = values.preparations?.[preparation.preparationGroupId]?.length || 0;
    if (preparation.mandatory && selectedSizeHasPreparation && preparationCount < 1) {
      errors.preparations[preparation.preparationGroupId] = "Required";
    } else if (preparation.limitedSelection && selectedSizeHasPreparation && preparationCount > Number(preparation.totalselections)) {
      errors.preparations[preparation.preparationGroupId] = "Cannot Exceed Limit";
    }
  }

  return errors;
}

export function getAdjustment(adjustments: Adjustments, menuId: string, now: Moment, booking: Booking | FunctionBooking, openingTimes: OpeningTimes): Adjustment {
  if (!openingTimes) return null;
  let ticks = 0;
  if (!booking || isStatusEqualByBooking(booking, "arrived", "seated")) {
    ticks = getTicks(now);
  } else {
    ticks = Number(booking.intervalId);
  }
  // conditionally add 24 hours if arrived or seated past booking day
  if (booking && isStatusEqualByBooking(booking, "arrived", "seated") && booking.date && Number(now.format("YYYYMMDD")) > booking.date) ticks += 24 * 60 * 60 * 1000; // add 24 hours
  const currentAdjustmentId = findKey(adjustments, (a) => {
    const { open: from1, close: to1 } = getOpeningTimesAdjusted(a.from, a.to);
    return a.menuId === menuId && ticks >= Number(from1) && ticks <= Number(to1);
  });
  let adjustment: Adjustment = null;
  if (currentAdjustmentId) {
    adjustment = adjustments[currentAdjustmentId];
  }
  return adjustment;
}

export function GetPrice(product: Product, productId: string, sizeId: string, restaurantId: string, adjustments: Adjustments, menuId: string, now: Moment, booking: Booking | FunctionBooking, openingTimes: OpeningTimes, priceOptions: PriceOptions, specialProduct: SpecialProduct = null, adjustedProductPriceInMenu: number = null) {
  const adjustment: Adjustment = getAdjustment(adjustments, menuId, now, booking, openingTimes);
  const priceOption = adjustment ? priceOptions[adjustment.priceOptionId] : null;
  if (specialProduct?.price) return Number(specialProduct.price);
  if (product?.restaurants && product.restaurants[restaurantId]) {
    let price: number = null;
    const allProducts = adjustment?.allProducts;
    if (allProducts) {
      price = getProductPrice(product, restaurantId, sizeId, priceOption, adjustedProductPriceInMenu);
    } else {
      if (adjustment?.productIds?.[productId]?.[sizeId]?.overridePrice) price = adjustment.productIds[productId][sizeId].overridePrice;
      else if (adjustment?.productIds?.[productId]?.[sizeId]?.enabled) price = getProductPrice(product, restaurantId, sizeId, priceOption, adjustedProductPriceInMenu);
      else price = getProductPrice(product, restaurantId, sizeId, null, adjustedProductPriceInMenu);
    }
    return Number(price);
  }
  return 0;
}

export function getProductPrice(product: Product, restaurantId, sizeId, priceOption: PriceOption = null, adjustedProductPriceInMenu: number = null) {
  let standardPrice = canThisBeNumber(adjustedProductPriceInMenu) ? Number(adjustedProductPriceInMenu) : getStandardPrice(product, sizeId, restaurantId);
  if (!priceOption || product.flags?.["useAsModifier"] || product?.flags?.["useAsAddition"]) return standardPrice;
  const nearest = priceOption.nearest ? Number(priceOption.nearest) : null;
  const diff = standardPrice * Math.abs(Number(priceOption.priceAdjustment) / 100);
  if (Number(priceOption.priceAdjustment) < 0) {
    standardPrice -= diff;
  } else {
    standardPrice += diff;
  }
  if (priceOption.rounding && nearest) {
    return priceOption.rounding === "roundUp" ? +Number(Math.round(standardPrice / nearest) * nearest).toFixed(2) : +Number(Math.floor(standardPrice / nearest) * nearest).toFixed(2);
  } else if (priceOption.rounding) {
    return priceOption.rounding === "roundUp" ? +Number(Math.round(standardPrice)).toFixed(2) : +Number(Math.floor(standardPrice)).toFixed(2);
  }
  return +Number(standardPrice).toFixed(2);
}

export function getProductPriceFormatted(product: Product, restaurantId, sizeId, priceOption, adjustedProductPriceInMenu: number = null) {
  const price = getProductPrice(product, restaurantId, sizeId, priceOption, adjustedProductPriceInMenu);
  return toCurrency(price);
}

export function getStandardPrice(product: Product, sizeId = "", restaurantId: string) {
  if (!sizeId && product) {
    const isFixedTypeProduct = product.typeId & 2;
    if (isFixedTypeProduct && product.fixedPriceMenuTypes) {
      sizeId = product.fixedPriceMenuTypes.adult;
    }
  }
  const price = product?.restaurants?.[restaurantId]?.price?.[sizeId];
  const standardPrice = sizeId && price !== undefined && price !== "" ? Number(price) : null;
  return standardPrice === null && product?.restaurants?.[restaurantId]?.price !== undefined ? Number(product.restaurants[restaurantId].price) : standardPrice;
}

/**
 * Sorts given GroupHeadingProductSizes based on ProductSizes order set in Office
 */
export const sortProductSizeIds = (productSizeIds: GroupHeadingProductSizes, sizes: ProductSize[] = []): GroupHeadingProductSizes => {
  const sortedProductSizeIds: GroupHeadingProductSizes = {};
  if (sizes.length === 0) return sortedProductSizeIds;
  const sizeIds = keys(productSizeIds).map((sizeId) => {
    return find(sizes, (s) => s?.id === sizeId);
  });
  for (let i = 0; i < sizeIds.length; i++) {
    const sortedSize = sizeIds[i];
    if (sortedSize) {
      const existingProps = find(productSizeIds, (value, key) => {
        return key === sortedSize?.id;
      });
      if (existingProps) {
        Object.assign(sortedProductSizeIds, { [sortedSize.id]: { ...existingProps } });
      }
    }
  }
  return sortedProductSizeIds;
};

export function groupHeadingToHierachy(groupHeadings: GroupHeadings, path: GroupHeadingHierachy[] = [], targetProductId = "", targetGroupHeadingId = ""): GroupHeadingHierachy[] {
  if (!targetProductId && !targetGroupHeadingId) return [];
  if (targetProductId && targetGroupHeadingId) throw new Error("Provide either ProductId or GroupHeadingId");
  for (const groupHeadingId in groupHeadings) {
    const currentHeading = groupHeadings[groupHeadingId];
    path.push({ groupHeadingId, name: currentHeading.displayName });
    // if it needs to check targetProductId
    if (targetProductId) {
      if (currentHeading.products?.[targetProductId]) {
        return path; // If the target node is found, return the recorded path.
      }
    }
    // if it needs to check targetGroupHeadingId
    else if (targetGroupHeadingId && targetGroupHeadingId === groupHeadingId) {
      return path; // If the target node is found, return the recorded path.
    }

    // Recursively search for the target node in the nested groupHeadings.
    const nestedPath = groupHeadingToHierachy(currentHeading.groupHeadings, path, targetProductId, targetGroupHeadingId);
    if (nestedPath.length) {
      return nestedPath; // Return the path if the target node is found in the nested groupHeadings.
    }
    path.pop(); // Backtrack if the target node is not found in the current groupHeadings.
  }
  return []; // Return undefined if the target node is not found in the current groupHeadings.
}

export const checkProductStockError = (productsStock: StockLimitProducts, productId: string, sizeId: string, productName: string) => {
  let error = "";
  const productStockLimit = productsStock && productsStock?.[productId] ? productsStock[productId] : null;
  const sizeOverride = productStockLimit && productStockLimit?.[sizeId] ? productStockLimit[sizeId] : null;
  if (sizeOverride && sizeOverride.isOverride) {
    if (isProductStockHidden(productsStock, productId, sizeId)) {
      error = `${productName} is hidden, try removing the product and try again`;
      return error;
    } else if (isProductStockSoldOut(productsStock, productId, sizeId)) {
      error = `${productName} is sold out, try removing the product and try again`;
      return error;
    } else if (sizeOverride.status === "applied") {
      //Todo: Check for stock limits validation
    }
  }
  return error;
};

export const isProductStockHidden = (productsStock: StockLimitProducts, productId: string, sizeId: string) => {
  const productStockLimit = productsStock && productsStock?.[productId] ? productsStock[productId] : null;
  const sizeOverride = productStockLimit && productStockLimit?.[sizeId] ? productStockLimit[sizeId] : null;
  if (sizeOverride && sizeOverride.isOverride && sizeOverride.status === "hide") {
    return true;
  }
  return false;
};

export const isProductStockSoldOut = (productsStock: StockLimitProducts, productId: string, sizeId: string) => {
  const productStockLimit = productsStock && productsStock?.[productId] ? productsStock[productId] : null;
  const sizeOverride = productStockLimit && productStockLimit?.[sizeId] ? productStockLimit[sizeId] : null;
  if (sizeOverride && sizeOverride.isOverride && sizeOverride.status === "sold") {
    return true;
  }
  return false;
};

function checkOrderItemStockError(productsStock: StockLimitProducts, productId: string, sizeId: string, productName: string) {
  let error = "";
  const productStockLimit = productsStock && productsStock?.[productId] ? productsStock[productId] : null;
  const sizeOverride = productStockLimit && productStockLimit?.[sizeId] ? productStockLimit[sizeId] : null;
  if (sizeOverride && sizeOverride.isOverride) {
    if (sizeOverride.status === "hide") {
      error = `${productName} is hidden, try removing the product and try again`;
      return error;
    } else if (sizeOverride.status === "sold") {
      error = `${productName} is sold out, try removing the product and try again`;
      return error;
    } else if (sizeOverride.status === "applied") {
      //Todo: Check for stock limits validation
    }
  }
  return error;
}

export const validateStock = (productsStock: StockLimitProducts, orderItems: OrderItem[]) => {
  let error = "";
  for (let index = 0; index < orderItems.length; index++) {
    const orderItem = orderItems[index];
    if (orderItem.orderStatus > OrderItemStatus.InBasket && orderItem.paidQuantity === orderItem.quantity) continue;
    error = checkOrderItemStockError(productsStock, orderItem.productId, orderItem.sizeId, orderItem.name);
    /** Find any stock limit error inside combo items  */
    if (!error && keys(orderItem.comboGroup).length > 0) {
      const comboGroup = orderItem.comboGroup;
      for (const productId in comboGroup) {
        const comboOrderItem = comboGroup[productId];
        error = checkOrderItemStockError(productsStock, productId, comboOrderItem.sizeId, comboOrderItem.name);
        if (error) break;
      }
    }
  }
  return error;
};

export function getProductStockToUpdate(productsStock: StockLimitProducts, specialsStock: StockLimitProducts, additionsStock: StockLimitProducts, modifiersStock: StockLimitProducts, date, restaurantId, tenantId, menuId, orderItems, orderType = "dineIn") {
  const update = {};
  forEach(orderItems, (oi) => {
    const additions = oi?.additions || null;
    const modifiers = oi?.modifiers || null;
    const productOrSpecialStock = productsStock?.[oi.productId]?.[oi.sizeId] || specialsStock?.[oi.productId]?.[oi.sizeId];
    if (productOrSpecialStock && productOrSpecialStock.isOverride && productOrSpecialStock.status === "applied") {
      const productOrSpecial = productsStock?.[oi.productId]?.[oi.sizeId] ? "products" : "specials";
      const stockCalculationResult = this.stockCalculation(productOrSpecialStock, oi.quantity, menuId, orderType);
      if (oi.paidQuantity !== oi.quantity) update[`${tenantId}/maestros/${date}/${restaurantId}/date/stockLimits/${productOrSpecial}/${oi.productId}/${oi.sizeId}`] = stockCalculationResult;
    }
    if (additions && additionsStock) {
      forEach(additions, (ads) => {
        forEach(ads, (ad) => {
          const stock = additionsStock?.[ad.productId]?.[ad.productSizeId];
          if (stock && stock.isOverride && stock.status === "applied") {
            const stockCalculationResult = this.stockCalculation(stock, ad?.quantity || 1, menuId, orderType);
            if (oi.paidQuantity !== oi.quantity) update[`${tenantId}/maestros/${date}/${restaurantId}/date/stockLimits/additions/${ad.productId}/${ad.productSizeId}`] = stockCalculationResult;
          }
        });
      });
    }
    if (modifiers && modifiersStock) {
      forEach(modifiers, (modifier) => {
        const stock = modifiersStock?.[modifier.productId]?.[modifier.productSizeId];
        if (stock && stock.isOverride && stock.status === "applied") {
          const stockCalculationResult = this.stockCalculation(stock, modifier?.quantity || 1, menuId, orderType);
          if (oi.paidQuantity !== oi.quantity) update[`${tenantId}/maestros/${date}/${restaurantId}/date/stockLimits/modifiers/${modifier.productId}/${modifier.productSizeId}`] = stockCalculationResult;
        }
      });
    }
  });
  return update;
}

/**
 * Sorts OrderModifier products based on array index of Product.modifiers
 */
export const sortOrderModiferProducts = (orderModifiers: OrderModifiers, parentProduct: Product): OrderModifier[] => {
  if (!orderModifiers) return [];
  if (!parentProduct) return values(orderModifiers);
  return chain(parentProduct.modifiers)
    .pickBy((mod) => orderModifiers[mod.modifierGroupId] !== undefined)
    .map((productModifier) => orderModifiers[productModifier.modifierGroupId])
    .value();
};

/**
 * Sorts orderItemProducts by SortSequence of ComboGroupProduct in ComboGroup
 */
export const sortComboGroupProducts = (orderItemProducts: OrderItemProducts, comboGroupList: ComboGroup[]): Partial<OrderItem>[] => {
  if (!orderItemProducts) return [];
  if (!comboGroupList || comboGroupList.length === 0) {
    return keys(orderItemProducts).map(productId => ({
      ...orderItemProducts[productId],
      productId,
    }));
  }

  return flatMap(comboGroupList, (combo) => {
    const sortedCombinations = orderBy(
      map(combo.combinations, (value, key) => ({ ...value, productId: key })),
      "sortSequence"
    );

    return compact(
      map(sortedCombinations, ({ productId, ...rest }) => {
        return has(orderItemProducts, productId) ? { ...orderItemProducts[productId], productId } : null
      })
    );
  });
};
