import { ALG } from "./ALG";
import { BookingType } from "./BookingType";
import { Table } from "./Box";
import { ButlerType } from "./Enums";
import { Guest } from "./Guest";
import { IHash } from "./IHash";
import { PickupDeliverySchedule } from "./PickupDeliverySchedule";
import { CashPaymentSetting, PaymentSummary } from "./transaction";

export interface GuestEmailData {
  customerId: string; // customer to send email to
  isRsvpSent: boolean;
  inviteFamily: boolean;
  invitationMessage: string;
  invitationImage: any;
  rsvpLink?: string;
  invitationManagementLink?: string;
  isNoOneAttending?: boolean;
  groupName?: string;
  allCustomerName?: string;
  numberOfAdultGuest?: number;
  numberOfChildrenGuest?: number;
  numberOfAdultGuestAttending?: number;
  numberOfChildrenGuestAttending?: number;
  numberOfAdultGuestNotAttending?: number;
  numberOChildrenGuestNotAttending?: number;
  attendingGuests?: Guest[];
  notAttendingGuests?: Guest[];
  isUserAttending: boolean; // is email customer attending
}

export interface Category {
  productName: string;
  productPrice: string;
  productSizeName: string;
  productId: string;
  productSizeId: string;
  quantity: number;
  subcategoryId: string;
  butlerTypeId: ButlerType;
  text: string[];
  categoryId: string;
  requiredPrepayment?: boolean;
  messages?: string[];
}

export interface Bookings {
  [bookingId: string]: Booking;
}

export interface Booking {
  _key?: string;
  date: number;
  mealId: string;
  widgetId: string;
  restaurantId: string;
  pax: number;
  uid?: string;
  customerId?: string;
  index?: number;
  status?: number;
  onMyWay?: number;
  continueTheWaitlist?: boolean;
  highlight?: boolean;
  enableOnlineOrdering?: boolean;
  ccOverride?: boolean;
  courseId?: string;
  menuId?: string;
  guests?: Guest[];
  tableNumber?: string;
  notAttending?: Guest[];
  lastUpdated?: number;
  intervalId?: number;
  casual?: boolean;
  casualToQR?: boolean;
  dateCreated?: number;
  standBy?: number;
  cancellationDate?: number;
  standByPriority?: number;
  standByIntervalId?: number;
  received?: { [k: string]: { productId: string; productSizeId: string } };
  standByQuote?: number;
  extendDepartureTime?: string;
  organizerSeat?: string;
  children?: number;
  standByBuffer?: number;
  invitationMessage?: string;
  invitationImage?: InvitationImage;
  creditCardDetailsConfirmed?: boolean;
  manualPaymentConfirmed?: boolean; // when a payment occurs outside the system cash/bank transfer/credit
  highChair?: number;
  groupAreaId: string;
  tableClassId?: string;
  qrCodeLink?: string;
  reference?: string;
  shortLink?: string;
  type?: BookingType;
  areaId?: string;
  tableOverride?: string[];
  combineFixedTables?: boolean;
  combineSectionTables?: boolean;
  extendDurationId?: string;
  endTime?: number;
  butlerPreferences?: {
    personalisationNote: string;
    wineRange: number[];
    dessertMessage: string;
  };
  paxLimitsOverride?: boolean;
  sectionLimitOverride?: boolean;
  adultBeveragePackageId?: string;
  adultBeveragePackageProductId?: string;
  childBeveragePackageId?: string;
  childBeveragePackageProductId?: string;
  expressMobile?: string;
  expressMobileCode?: string;
  expressFirstName?: string;
  expressLastName?: string;
  qrMobile?: string;
  qrMobileCode?: string;
  extraPax?: number;
  categories?: Category[];
  customerNote?: string;
  casualName?: string;
  staffNote?: string;
  referralId?: string;
  referralNote?: string;
  alternateCustomerId?: string;
  madeByNotAttending?: boolean;
  allergies?: Array<{ label: string; value: string }>;
  dietaryRequirements?: Array<{ label: string; value: string }>;
  occasionId?: string;
  menuOptionId?: string;
  statusChangeData?: {
    Confirmed?: number;
    Cancelled?: number;
    NoShow?: number;
    Vacated?: number;
    Seated?: number;
    Arrived?: number;
  };
  payLater?: boolean;
  /** @deprecated */
  cashPayment?: boolean;
  cashPaymentSetting?: CashPaymentSetting;
  alg?: ALG;
  paymentSummary?: PaymentSummary;
  noShow?: {
    enabled?: boolean;
    adults?: number;
    children?: number;
  };
  cancelled?: {
    enabled?: boolean;
    adults?: number;
    children?: number;
  };
  schedule?: PickupDeliverySchedule;
}

export interface AssignmentSection {
  order: {
    [boxId: string]: number;
  };
  joins: IHash<Join>;
  reserves: {
    [boxId: string]: Table;
  };
  heads?: {
    boxId: string;
    left: number;
    right: number;
  }[];
}

export interface Assignment {
  restaurantId: string;
  sections: {
    [sectionId: string]: AssignmentSection;
  };
}

export interface Assignments {
  [assignmentId: string]: Assignment;
}

export interface Join {
  bookingId?: string;
  enabled: boolean;
  initial?: boolean;
  orphan?: boolean;
  tables: {
    [boxId: string]: boolean;
  };
  spacing?: number;
}

export type InvitationImage = {
  url: string;
  thumbUrl: string;
  sizeFile: number;
  sizeThumb: number;
  fileName: string;
  name: string;
  type: SupportedImageMimeType;
  ext: string;
};

export enum SupportedImageMimeType {
  Jpg = "image/jpeg",
  Png = "image/png",
}
